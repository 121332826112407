import '../styles/style.scss';

var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};
(function($) {
    if (!isMobile.any()) {
        $(window).on("load", function() {
            require('bootstrap');
            require("jquery-mousewheel")($);
            require('malihu-custom-scrollbar-plugin')($);

            $("#content").mCustomScrollbar({
                axis: "x",
                theme: "light-3",
                advanced: { autoExpandHorizontalScroll: true }
            });
        });
    }

    $('.loadmore_posts').click(function() {
        event.preventDefault();
        let button = $(this),
            data = {
                'action': 'loadmore',
                'query': writings.posts,
                'page': writings.current_page
            };
        $.ajax({
            url: writings.ajaxurl,
            data: data,
            type: 'POST',
            beforeSend: function(xhr) {
                button.text('Loading...');
            },
            success: function(data) {
                if (data) {
                    button.text('load more').parent().before(data);
                    writings.current_page++;

                    if (writings.current_page == writings.max_page)
                        button.remove();
                } else {
                    button.remove();
                }
            }
        });
    });

    $('.loadmore_podcasts').click(function() {
        event.preventDefault();
        let button = $(this),
            data = {
                'action': 'podcasts',
                'query': podcasts.posts,
                'page': podcasts.current_page
            };
        $.ajax({
            url: podcasts.ajaxurl,
            data: data,
            type: 'POST',
            beforeSend: function(xhr) {
                button.text('Loading...');
            },
            success: function(data) {
                if (data) {
                    button.text('load more').parent().parent().before(data);
                    podcasts.current_page++;

                    if (podcasts.current_page == podcasts.max_page)
                        button.remove();
                } else {
                    button.remove();
                }
            }
        });
    });

    $('.loadmore_taxposts').click(function() {
        event.preventDefault();
        let button = $(this),
            data = {
                'action': 'taxposts',
                'query': taxposts.posts,
                'page': taxposts.current_page
            };
        $.ajax({
            url: taxposts.ajaxurl,
            data: data,
            type: 'POST',
            beforeSend: function(xhr) {
                button.text('Loading...');
            },
            success: function(data) {
                if (data) {
                    button.text('load more').parent().parent().before(data);
                    taxposts.current_page++;

                    if (taxposts.current_page == taxposts.max_page)
                        button.remove();
                } else {
                    button.remove();
                }
            }
        });
    });

    $('a[href="#sign-up"]').on('click', function(e) {
        e.preventDefault();
        var id = $(this).attr('href');
        var top = $(id).offset().top;
        $('body,html').animate({ scrollTop: top }, 1000);
    });


})(jQuery);

jQuery(function($) {

    $('.menu-item-has-children > .dropdown-toggle').after('<span class="opener"></span>')
    if ($(window).width() > 767) {
        $('.menu-item-has-children').hover(
            function() {
                $(this).addClass('show');
                $(this).find('.dropdown-menu').addClass('show');
            },
            function() {
                $(this).removeClass('show');
                $(this).find('.dropdown-menu').removeClass('show');
            }
        );
    } else {
        $(".navbar-dark .navbar-nav .menu-item.dropdown .opener").on("click", function() {
            event.preventDefault();
            let li = $(this).parent();
            li.toggleClass('show');
            li.find('.dropdown-menu').toggleClass('show');
        });
    }
});